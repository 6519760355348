import React from 'react';
import Header from './header';
import Footer from './footer';
import Slider from './Slider';
import About from './About';
import HowWeWork from './HowWeWork';
import Screenshot from './Screenshot';
import Faq from './Faq';

const HomePage = () => {
  return (
    <div className="site-bg">
      <Header />
      <Slider />
      <About />
      <HowWeWork />
      <Screenshot />
      <Faq />
      <Footer />
    </div>
  );
};

export default HomePage;
