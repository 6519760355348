import React from 'react'
import about from "../assets/images/about-image.png"
import dot from "../assets/images/dot.png"

const About = () => {
  return (
    <>
    
    <section id="aboutUs" className="about-section section-padding">
        <div className="container">
          <div className="about-section-grid">
            <div className="about-grid-columns">
              <div className="about-image">
                <img src={about} alt="About Us" />
              </div>
            </div>
            <div className="about-grid-columns">
              <div className="about-des">
                <div className="section-heading">
                  <h5>
                    <img src={dot} alt="dot" /> About Us
                  </h5>
                  <h2>
                    <span>Freshness</span> and Convenience, Anytime
                  </h2>
                  <p>
                    We bring convenience and quality to your kitchen with our wide range of frozen meals and ingredients. From wholesome, ready-to-eat dinners to perfectly preserved fruits, vegetables, and proteins, our frozen products are crafted to lock in freshness and flavor. Whether you're preparing a quick lunch, a family dinner, or just stocking up for the week, our offerings are designed to save you time without sacrificing taste. Discover the ease of nutritious and delicious food, available whenever you need it, we believe in making mealtime simple and satisfying.
                  </p>
                  <p>
                    Whether you're preparing a quick lunch, a family dinner, or just stocking up for the week, our offerings are designed to save you time without sacrificing taste. Discover the ease of nutritious and delicious food, available whenever you need it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About