import React from 'react'
import appStore from '../assets/images/app-store-btn.png'
import googlePlay from "../assets/images/google-play-btn.png"
import device from "../assets/images/device-image.png"
import shape01 from "../assets/images/shape01.png"
import shape02 from "../assets/images/shape02.png"

const Slider = () => {
  return (
    <>
    <section className="slider-section">
        <div className="container">
          <div className="slider-des-row">
            <div className="slider-des-columns slider-des-box">
              <h1>
                Enjoy <span>Frozen Food</span> delivered at your doorstep!
              </h1>
              <p>
                We bring convenience and quality to your kitchen with our wide range of frozen meals and ingredients. From wholesome, ready-to-eat dinners to perfectly preserved fruits, vegetables, and proteins, our frozen products are crafted to lock in freshness and flavor.
              </p>
              <div className="app-download-btn">
                <a href="/">
                  <img src={appStore} alt="App Store" />
                </a>
                <a href="javascript:;">
                  <img src={googlePlay} alt="Google Play" />
                </a>
              </div>
            </div>

            <div className="slider-des-columns slider-image-box">
              <div className="device-image-box">
                <img src={device} alt="Device" />
              </div>
            </div>
          </div>
          <div className="shape-box01">
            <img src={shape01} alt="shape" />
          </div>
          <div className="shape-box02">
            <img src={shape02} alt="shape" />
          </div>
        </div>
      </section>
    </>
  )
}

export default Slider