import HomePage from './Components/HomePage';

function App() {
  return (
    <div className="App">
     <HomePage />
    </div>
  );
}

export default App;
