import React, { useEffect, useState } from 'react';
import axios from 'axios';
import icon1 from '../assets/images/s-icon1.png';
import icon2 from '../assets/images/s-icon2.png';
import icon3 from '../assets/images/s-icon3.png';
import { API_BASE } from '../constant';

const Footer = () => {
  const [socialLinks, setSocialLinks] = useState({
    instaLink: '#',
    fbLink: '#',
    twitterLink: '#'
  });

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await axios.get(API_BASE + 'cms/getLinks'); // Replace with your API endpoint
        console.log("res",response);
        const { instaLink, fbLink, twitterLink } = response?.data?.data; // Destructure links from the API response
        setSocialLinks({ instaLink, fbLink, twitterLink }); // Update the state with social links
      } catch (error) {
        console.log('Error fetching social links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <footer>
      <section className="top-footer">
        <div className="container">
          <div className="follow-box">
            <h5>Follow us</h5>
            <ul>
              <li><a href={socialLinks.instaLink} target="_blank" rel="noopener noreferrer"><img src={icon3} alt="instagram" /></a></li>
              <li><a href={socialLinks.fbLink} target="_blank" rel="noopener noreferrer"><img src={icon1} alt="facebook" /></a></li>
              <li><a href={socialLinks.twitterLink} target="_blank" rel="noopener noreferrer"><img src={icon2} alt="twitter" /></a></li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bottom-footer">
        <div className="container">
          <div className="bottom-footer-inner">
            <ul className="footer-menu">
              <li><a href="https://admin.woodfordjerkcatering.com/viewTerms2">Terms and conditions</a></li>
              <li><a href="https://admin.woodfordjerkcatering.com/privacy-policy">Privacy Policy</a></li>
            </ul>
            <p className="copyright-box">Copyright © App. All Rights Reserved.</p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
