import React, { useState } from 'react';
import logo from "../assets/images/logo.png"

const Header = () => {
  const [activeTab, setActiveTab] = useState("#aboutUs")

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  }

  return (
    <header>
      <div className="container-fluid">
        <div className="header-inner">
          <div className="logo">
            <a href="#"><img src={logo} alt="logo" /></a>
          </div>
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className={`nav-link ${activeTab === '#aboutUs' ? 'active' : ''}`} aria-current="page" href="#aboutUs" onClick={() => handleActiveTab("#aboutUs")}>
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activeTab === '#howWorks' ? 'active' : ''}`} href="#howWorks" onClick={() => handleActiveTab("#howWorks")}>
                    How We Work
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activeTab === '#appScreenshots' ? 'active' : ''}`} href="#appScreenshots" onClick={() => handleActiveTab("#appScreenshots")}>
                    App Screenshots
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${activeTab === '#faq' ? 'active' : ''}`} href="#faq" onClick={() => handleActiveTab("#faq")}>
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* <div className="head-right">
            <div className="contact-btn">
              <a href="#" className="btn">Know More</a>
            </div>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
