import React from 'react'
import dot from "../assets/images/dot.png"
import icon1 from "../assets/images/work-icon01.png"
import icon2 from "../assets/images/work-icon02.png"
import icon3 from "../assets/images/work-icon03.png"
import icon4 from "../assets/images/work-icon04.png"

const HowWeWork = () => {
  return (
    <>
     <section id="howWorks" className="how-we-work-section section-padding">
        <div className="container">
          <div className="section-heading text-center">
            <h5 className="justify-content-center">
              <img src={dot} alt="dot" /> How We Work
            </h5>
            <h2>
              Simple <span>Steps</span> to Order Food
            </h2>
            <p>How It Works: Fresh, Frozen, Delivered</p>
          </div>

          <div className="grid-row-4">
            {[
              {
                number: '01',
                icon: icon1,
                title: 'Explore Our Products',
                description: 'Discover a Variety of Delicious, Ready-to-Enjoy Frozen Meals.',
              },
              {
                number: '02',
                icon: icon2,
                title: 'Add to Cart',
                description: 'Ready to Enjoy? Add Your Favorites to Cart with a Click!',
              },
              {
                number: '03',
                icon: icon3,
                title: 'Checkout & Pay',
                description: 'Complete Your Purchase in Just a Few Steps!',
              },
              {
                number: '04',
                icon: icon4,
                title: 'Food Delivered',
                description: 'Fast and Fresh: Frozen Goodness Delivered Right to Your Door!',
              },
            ].map((step, index) => (
              <div className="how-work-box" key={index}>
                <div className="number-box">{step.number}</div>
                <figure>
                  <img src={step.icon} alt="work" />
                </figure>
                <figcaption>
                  <h4>{step.title}</h4>
                  <p>{step.description}</p>
                </figcaption>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default HowWeWork