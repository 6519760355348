import React from 'react'
import shape03 from "../assets/images/shape03.png"
import device01 from "../assets/images/device01.png"
import device02 from "../assets/images/device02.png"
import device03 from "../assets/images/device03.png"
import device04 from "../assets/images/device04.png"

const Screenshot = () => {
  return (
    <section id="appScreenshots" className="app-screenshot-section section-padding">
    <div className="container">
      <div className="section-heading text-center">
        <h2>
          A Glimpse of Our <span>User-Friendly</span> Experience
        </h2>
        <p>Explore Our User-Friendly Interface</p>
      </div>

      <div className="app-screenshot-row">
        {[device01, device02, device03, device04].map((device, index) => (
          <div className="app-screenshot-columns" key={index}>
            <img src={device} alt="device" />
          </div>
        ))}
      </div>
      <div className="shape03">
        <img src={shape03} alt="shape03" />
      </div>
    </div>
  </section>

  )
}

export default Screenshot